/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { Icon, Image } from '..';
import Checkmark from '../../assets/images/image_FUTURE_checkmark.png';
import readAWSS3File from '../../utils/readAWSS3File';
import { IconSprite } from '../Icon/Icon';

type AlertProps = {
  show: boolean;
  type: 'SUCCESS' | 'ERROR' | 'WARNING' | 'INFO';
  message: string;
  onClose: () => void;
  onClick?: () => void;
  pending?: boolean;
  className?: string;
};

function Alert({ show, type, message, className, pending, onClose, onClick }: AlertProps) {
  const alertRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (show) {
      alertRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }, [show]);

  const handleOnClose = (e: any) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <div
      ref={alertRef}
      onClick={onClick}
      role="presentation"
      className={`absolute top-0 font-normal flex items-center 
      justify-center text-sm space-x-4 right-0 left-0 text-center transition-all 
      ease-in-out ${className || ''} ${onClick ? 'cursor-pointer' : 'cursor-default'}
      ${show ? 'min-h-[50px] p-[5px]' : 'h-0'}
      ${
        type === 'SUCCESS'
          ? '!bg-[#E8FFD6] !text-future-black'
          : type === 'WARNING'
          ? '!bg-[#FFEDD4] !text-future-black'
          : type === 'INFO'
          ? '!bg-[#EEFBFF] !text-future-black'
          : 'bg-[#FFD4D4] text-future-black'
      }`}
    >
      {show && (
        <>
          {pending ? (
            <span className="pending-alert">{message}</span>
          ) : (
            <>
              <span>{message}</span>
              <div role="presentation" onClick={handleOnClose} className="p-[10px]">
                <Icon id="close" className="w-[12px] h-[12px] cursor-pointer" />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export function IncomingCallAlert({
  show,
  caller,
  onAcceptCall,
}: {
  show: boolean;
  caller: { name: string; photo: string };
  onAcceptCall: () => void;
}) {
  const [signedProviderPhoto, setSignedProviderPhoto] = React.useState();

  React.useEffect(() => {
    if (!caller.photo) return;

    (async function readProviderPhoto() {
      const signedPhoto = await readAWSS3File('avatar', caller.photo);
      setSignedProviderPhoto(signedPhoto);
    })();
  }, [caller.photo]);

  return (
    <div
      className={`bg-future-black fixed pl-3 pr-4 py-3 top-24 rounded-xl shadow-md space-x-4 min-w-[400px] 
      text-white flex flex-row items-center justify-between text-[15px] transition-all ease-in-out ${
        show ? 'right-16 z-50' : 'right-[-150%]'
      }`}
    >
      <div
        className="h-fit w-fit bg-white rounded-full inline-flex  
        justify-center items-center shrink-0 font-medium"
      >
        <Image
          className="w-10 h-10 rounded-full"
          src={signedProviderPhoto || 'image_future_provider_avatar_placeholder.svg'}
          alt="provider avatar"
        />
      </div>
      <div className="flex flex-row flex-1 space-x-4 items-center">
        <div className="leading-normal">
          <span className="font-medium block pending-alert after:!leading-none after:!tracking-widest after:!text-xs">
            {caller.name} Calling{' '}
          </span>
          <span className="font-light text-[13px]">Incoming video call request</span>
        </div>
        <span className="h-auto w-[1px] block bg-[#E7E7E74D]/30 self-stretch" />
        <button
          onClick={onAcceptCall}
          type="button"
          className="text-white bg-[#72C16D] rounded-full font-light text-[13px] inline-flex 
          justify-center items-center pl-2 pr-3 shrink-0"
        >
          <IconSprite id="icon-incoming-call" className="w-[15px] h-[15px] mr-[5px]" />
          Accept
        </button>
      </div>
    </div>
  );
}

export function VisitAlert({
  type,
  onClick,
  visit,
}: {
  onClick: (closeIt?: boolean) => void;
  visit: string;
  type: 'COMPLETED-VISIT' | 'PENDING-VISIT' | 'MEDICAL-INFO-REQUIRED' | 'QUESTION-ASKED';
}) {
  return type === 'COMPLETED-VISIT' && visit ? (
    <span
      className="bg-secondary-gradient flex flex-col tablet:flex-row rounded-md leading-relaxed pl-4 
      pr-5 py-2 justify-between w-full space-y-1 tablet:items-center tablet:space-x-6"
    >
      <div className="flex items-center">
        <span className="w-9 h-9 tablet:flex flex-row justify-center items-center shrink-0 mr-3 hidden">
          <img src={Checkmark} className="w-9 h-9" alt="visit complete graphic" />
        </span>
        <p>Your treatment plan for your {visit} is now ready for viewing</p>
      </div>
      <button
        onClick={() => onClick(true)}
        className="text-future-black font-medium shrink-0 w-fit text-xs"
        type="button"
      >
        Review Here
      </button>
    </span>
  ) : type === 'PENDING-VISIT' && visit ? (
    <span
      className="border border-[#F6914F]/30 bg-[#FFEDD4] flex flex-col tablet:flex-row rounded-md leading-relaxed pl-4 
      pr-5 py-2 justify-between w-full space-y-1 tablet:items-center tablet:space-x-6"
    >
      <div className="flex items-center">
        <p>Your {visit} is under review.</p>
      </div>
      <button onClick={() => onClick()} className="shrink-0 w-fit text-xs" type="button">
        Click Here
      </button>
    </span>
  ) : type === 'QUESTION-ASKED' ? (
    <span
      className="border border-[#F6914F]/30 bg-[#FFEDD4] flex flex-col tablet:flex-row rounded-md leading-relaxed pl-4 
      pr-5 py-2 justify-between w-full space-y-1 tablet:items-center tablet:space-x-6"
    >
      <div className="flex items-center">
        <p>You have a Question from your Primary Care Provider.</p>
      </div>
      <button
        onClick={() => onClick()}
        className="text-future-black font-medium shrink-0 w-fit text-xs"
        type="button"
      >
        Click Here
      </button>
    </span>
  ) : type === 'MEDICAL-INFO-REQUIRED' ? (
    <span
      className="bg-secondary-gradient flex flex-col tablet:flex-row rounded-md leading-relaxed pl-4 
    pr-5 py-2 justify-between w-full space-y-1 tablet:items-center tablet:space-x-6"
    >
      <div className="flex items-center">
        <span className="w-8 h-8 tablet:flex flex-row justify-center items-center shrink-0 mr-3 hidden">
          <img src={Checkmark} className="w-8 h-8" alt="visit complete graphic" />
        </span>
        <p>Update your medical information.</p>
      </div>
      <button
        onClick={() => onClick()}
        className="text-future-blue font-medium shrink-0 w-fit text-xs"
        type="button"
      >
        Click Here
      </button>
    </span>
  ) : null;
}

Alert.defaultProps = {
  className: '',
  pending: false,
  onClick: undefined,
};

export default Alert;

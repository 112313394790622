import React from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import IconEmojiHi from '../../assets/images/image_FUTURE_Hi.png';
import { Button, MarkdownHTML } from '..';
import env from '../../utils/env';
import { reportErrorToSentry } from '../../utils/reportToSentry';

type ErrorFallbackProps = {
  error: Error;
  resetErrorBoundary: (...args: Array<unknown>) => void;
};

export function ErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProps) {
  React.useEffect(() => {
    reportErrorToSentry(error);
  }, [error]);

  return (
    <div role="alert" className="flex flex-col items-center justify-center pt-10 tablet:pt-14">
      <h3 className="text-future-black font-futuristagothic text-[30px] mb-2">Sorry !!!</h3>
      <div className="w-[70%] mx-auto text-center">
        <pre className="inline-flex whitespace-pre-wrap">
          {error?.message || 'Something unusual happened. Kindly retry your last action.'}
        </pre>
      </div>

      <img src={IconEmojiHi} alt="error" className="w-[150px] tablet:w-[150px]" />

      <Button label="Try again!" onClick={resetErrorBoundary} className="w-[200px]" />

      <MarkdownHTML
        className="text-future-black text-xs mt-6 text-center"
        content={`If this persists, please reach out to our [support team.](${env(
          'FUTURE_APP_URL',
        )}/support)`}
      />
    </div>
  );
}

function ErrorBoundary({ children }: { children: JSX.Element }) {
  return (
    <ReactErrorBoundary FallbackComponent={ErrorFallback} onReset={() => false}>
      {children}
    </ReactErrorBoundary>
  );
}

export default ErrorBoundary;

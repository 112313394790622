import * as CryptoJS from 'crypto-js';
import env from './env';

const passPhrase = env('FUTURE_PASSPHRASE') || '';

const getFromLocalStorage = (key: string) => {
  const decipheredValue = CryptoJS.AES.decrypt(localStorage.getItem(key) || '', passPhrase);

  return decipheredValue.toString(CryptoJS.enc.Utf8);
};

export const getFromSessionStorage = (key: string) => {
  const decipheredValue = CryptoJS.AES.decrypt(sessionStorage.getItem(key) || '', passPhrase);
  return decipheredValue.toString(CryptoJS.enc.Utf8);
};

export default getFromLocalStorage;

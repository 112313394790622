const resizeImage = (file: File, resizingFactor = 0.5): Promise<File> => {
  return new Promise((resolve, reject) => {
    const image = new Image();

    image.onload = () => {
      const { width } = image;
      const { height } = image;

      const newWidth = width * resizingFactor;
      const newHeight = height * resizingFactor;

      const canvas = document.createElement('canvas');
      canvas.width = newWidth;
      canvas.height = newHeight;

      const context = canvas.getContext('2d');

      if (!context) {
        resolve(file);
        return;
      }

      context.drawImage(image, 0, 0, newWidth, newHeight);

      canvas.toBlob(blob => {
        if (blob) {
          const resizedFile = new File([blob], file.name, { type: file.type });
          resolve(resizedFile);
        }
      }, file.type);
    };

    image.onerror = error => {
      reject(error);
    };

    image.src = URL.createObjectURL(file);
  });
};

export default resizeImage;

/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import Icons from '../../assets/icons/indexSVG';
import { ReactComponent as IconSuccessDiscountCode } from '../../assets/icons/icon-success-discount-code.svg';
import { ReactComponent as IconUserProfileMenu } from '../../assets/icons/icon_user_profile_menu.svg';
import { ReactComponent as IconUserProfileMenuLogout } from '../../assets/icons/icon_user_profile_menu_logout.svg';
import { ReactComponent as IconProfileCameraUpload } from '../../assets/icons/icon_profile_camera_upload.svg';
import { ReactComponent as IconMessageTimestampAttached } from '../../assets/icons/icon_message_timestamp_attached.svg';
import { ReactComponent as IconIDVerificationUpload } from '../../assets/icons/icon_id_verification_upload.svg';
import { ReactComponent as IconSelfieVerificationUpload } from '../../assets/icons/icon_selfie_verification_upload.svg';
import { ReactComponent as IconInputFileUpload } from '../../assets/icons/icon_input_file_upload.svg';
import { ReactComponent as IconSleepEmoji } from '../../assets/icons/icon_sleep_emoji.svg';
import { ReactComponent as IconLongArrowRight } from '../../assets/icons/icon_long_arrow_right.svg';
import { ReactComponent as IconExpandAndView } from '../../assets/icons/icon_expand_and_view.svg';
import { ReactComponent as IconCareNotesAttachment } from '../../assets/icons/icon_care_notes_attachment.svg';
import { ReactComponent as IconArrivalScheduling } from '../../assets/icons/icon-arrival-scheduling.svg';
import { ReactComponent as IconKiosk } from '../../assets/icons/icon-kiosk.svg';
import { ReactComponent as IconAddFamily } from '../../assets/icons/icon_user_profile_menu_add_family.svg';
import { ReactComponent as IconHintInfo } from '../../assets/icons/icon_hint_info.svg';
import { ReactComponent as IconApptClinicsPlaceholder } from '../../assets/icons/icon_appointment_clinics_placeholder.svg';
import { ReactComponent as IconApptProviderPlaceholder } from '../../assets/icons/icon_appointment_provider_placeholder.svg';
import { ReactComponent as IconCardActivate } from '../../assets/icons/icon_user_profile_menu_card_activation.svg';
import { ReactComponent as IconReimbursement } from '../../assets/icons/icon_user_profile_menu_reimbursement.svg';
import { ReactComponent as IconPresIdCard } from '../../assets/icons/icon_user_profile_menu_id_card.svg';
import { ReactComponent as IconEmergencyCall } from '../../assets/icons/icon_emergency_call.svg';
import { ReactComponent as IconMail } from '../../assets/icons/icon_mail.svg';
import { ReactComponent as IconIdPhoto } from '../../assets/icons/icon_id_photo.svg';
import { ReactComponent as IconSelfiePhoto } from '../../assets/icons/icon_selfie_photo.svg';
import { ReactComponent as IconDoctorVirtualCare } from '../../assets/icons/icon_doctor_virtual_care.svg';
import { ReactComponent as IconVisaLogo } from '../../assets/icons/icon_visa_logo.svg';
import { ReactComponent as IconClinicServiceBookAppt } from '../../assets/icons/icon_clinic_service_book_appointment.svg';
import { ReactComponent as IconThumbsUp } from '../../assets/icons/icon_thumbs_up.svg';
import { ReactComponent as IconInsuranceBenefit } from '../../assets/icons/icon_user_profile_menu_insurance_benefit.svg';
import { ReactComponent as IconSearch } from '../../assets/icons/icon_search.svg';
import { ReactComponent as IconChatCardBirthControlPill } from '../../assets/icons/icon_chat_card_birth_control_pill.svg';
import { ReactComponent as IconChatCardSickVisitPill } from '../../assets/icons/icon_chat_card_sick_visit_pill.svg';
import { ReactComponent as IconChatCardUTI } from '../../assets/icons/icon_chat_card_uti.svg';
import { ReactComponent as IconChatCardMentalHealthTreatment } from '../../assets/icons/icon_chat_card_mental_health_treatment.svg';
import { ReactComponent as IconChatCardSTDDiagnosis } from '../../assets/icons/icon_chat_card_std_diagnosis.svg';
import { ReactComponent as IconChatCardSTDTest } from '../../assets/icons/icon_chat_card_std_test.svg';
import { ReactComponent as IconChatCardMenstrualIrregularities } from '../../assets/icons/icon_chat_card_menstrual_irregularities.svg';
import { ReactComponent as IconChatCardVaginalInfections } from '../../assets/icons/icon_chat_card_vaginal_infections.svg';
import { ReactComponent as IconChatCardErectileDysfunction } from '../../assets/icons/icon_chat_card_erectile_dysfunction.svg';
import { ReactComponent as IconChatCardHairSkin } from '../../assets/icons/icon_chat_card_hair_skin.svg';
import { ReactComponent as IconChatCardRxRefill } from '../../assets/icons/icon_chat_card_rx_refill.svg';
import { ReactComponent as IconChatCardSexualHealth } from '../../assets/icons/icon_chat_card_sexual_health.svg';

type IconProps = {
  id: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
};

const allIcons: Record<string, (props: React.SVGProps<SVGSVGElement>) => JSX.Element | any> = {
  ...Icons,
  successDiscountCode: IconSuccessDiscountCode,
  iconUserProfileMenu: IconUserProfileMenu,
  iconUserProfileMenuLogout: IconUserProfileMenuLogout,
  iconProfileCameraUpload: IconProfileCameraUpload,
  iconMessageTimestampAttached: IconMessageTimestampAttached,
  iconIDVerificationUpload: IconIDVerificationUpload,
  iconSelfieVerificationUpload: IconSelfieVerificationUpload,
  iconInputFileUpload: IconInputFileUpload,
  iconSleepEmoji: IconSleepEmoji,
  iconLongArrowRight: IconLongArrowRight,
  iconExpandAndView: IconExpandAndView,
  iconCareNotesAttachment: IconCareNotesAttachment,
  iconArrivalScheduling: IconArrivalScheduling,
  iconKiosk: IconKiosk,
  iconAddFamily: IconAddFamily,
  iconHintInfo: IconHintInfo,
  iconApptClinicsPlaceholder: IconApptClinicsPlaceholder,
  iconApptProviderPlaceholder: IconApptProviderPlaceholder,
  iconCardActivate: IconCardActivate,
  iconReimbursement: IconReimbursement,
  iconPresIdCard: IconPresIdCard,
  iconEmergencyCall: IconEmergencyCall,
  iconMail: IconMail,
  iconIdPhoto: IconIdPhoto,
  iconSelfiePhoto: IconSelfiePhoto,
  iconDoctorVirtualCare: IconDoctorVirtualCare,
  iconVisaLogo: IconVisaLogo,
  iconClinicServiceBookAppt: IconClinicServiceBookAppt,
  iconThumbsUp: IconThumbsUp,
  iconInsuranceBenefit: IconInsuranceBenefit,
  iconSearch: IconSearch,
  iconChatCardBirthControlPill: IconChatCardBirthControlPill,
  iconChatCardSickVisitPill: IconChatCardSickVisitPill,
  iconChatCardUTI: IconChatCardUTI,
  iconChatCardMentalHealthTreatment: IconChatCardMentalHealthTreatment,
  iconChatCardSTDDiagnosis: IconChatCardSTDDiagnosis,
  iconChatCardSTDTest: IconChatCardSTDTest,
  iconChatCardMenstrualIrregularities: IconChatCardMenstrualIrregularities,
  iconChatCardVaginalInfections: IconChatCardVaginalInfections,
  iconChatCardErectileDysfunction: IconChatCardErectileDysfunction,
  iconChatCardHairSkin: IconChatCardHairSkin,
  iconChatCardRxRefill: IconChatCardRxRefill,
  iconChatCardSexualHealth: IconChatCardSexualHealth,
};

function Icon({ id, ...rest }: IconProps) {
  const SelectedIcon = allIcons[id];
  return SelectedIcon ? <SelectedIcon id={id} {...rest} /> : null;
}

export function IconSprite({ id, ...rest }: IconProps) {
  return (
    <svg {...rest}>
      <use href={`/assets/icons/sprite.svg#${id}`} />
    </svg>
  );
}

Icon.defaultProps = {
  className: '',
  style: undefined,
  onClick: () => false,
};

export default Icon;

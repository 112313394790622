/* eslint-disable @typescript-eslint/no-shadow */
import * as React from 'react';
import { RadioInput } from '../..';

type Answer = {
  questionKey: string;
  answers: Array<string>;
};

type RadioInputBuilderProps = {
  id: string;
  label: string;
  value: string;
  error?: string;
  notice?: string;
  inputs: Array<{
    id: string;
    priority: number;
    text: string;
  }>;
  onInputChange: (answers: Answer) => void;
};

function RadioInputBuilder({
  id,
  label,
  value,
  error,
  notice,
  inputs,
  onInputChange,
}: RadioInputBuilderProps) {
  const [checkedItem, setCheckedItem] = React.useState<string>(value);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    if (checked) {
      setCheckedItem(value);
      onInputChange({ questionKey: id, answers: [value] });
    }
  };

  return (
    <div>
      <div className="flex flex-col mb-6">
        <label htmlFor={id} className="leading-8 font-futuristagothic text-future-black text-2xl">
          {label}
        </label>
        {notice && <span className="text-xs text-future-deep-gray mt-1 font-medium">{notice}</span>}
      </div>
      <div className="grid grid-cols-1 gap-5">
        {inputs &&
          inputs.map(input => (
            <label
              key={`${id}_${input.id}`}
              htmlFor={`${id}_${input.id}`}
              className="text-sm block pl-[18px] -indent-[18px] cursor-pointer"
            >
              <RadioInput
                id={`${id}_${input.id}`}
                name={id}
                value={input.id}
                checked={checkedItem === input.id}
                onChange={handleInputChange}
                className="align-middle cursor-pointer"
              />{' '}
              <span className="align-middle">{input.text}</span>
            </label>
          ))}
      </div>
      {error && <span className="text-future-red text-left text-xs mt-2 inline-flex">{error}</span>}
    </div>
  );
}

RadioInputBuilder.defaultProps = {
  notice: undefined,
  error: undefined,
};

export default RadioInputBuilder;

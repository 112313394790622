/* eslint-disable react/button-has-type */
import React from 'react';
import { Icon } from '..';

type ButtonProps = {
  id?: string;
  label: string;
  type?: 'button' | 'submit' | 'reset';
  onClick?: (value: any) => void;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
};

function Button({ id, label, type, className, disabled, loading, onClick }: ButtonProps) {
  return (
    <button
      id={id}
      type={type}
      disabled={disabled}
      onClick={!disabled ? onClick : undefined}
      className={`px-9 py-3 text-sm rounded-full bg-primary-gradient justify-center text-future-black 
      inline-flex items-center hover:opacity-80 cursor-pointer transition-all ease-linear whitespace-nowrap 
      ${
        loading ? 'disabled:cursor-wait !px-6' : 'disabled:cursor-not-allowed'
      }  disabled:text-future-deep-gray/50 disabled:bg-primary-gradient-disabled
        ${className}`}
    >
      {!(disabled && loading) ? label : 'Please wait...'}
      {disabled && loading && <Icon id="loader" className="w-5 h-5 ml-2" />}
    </button>
  );
}

Button.defaultProps = {
  id: '',
  className: '',
  type: 'button',
  disabled: false,
  loading: false,
  onClick: undefined,
};

export default Button;

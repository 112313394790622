import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Amplify from 'aws-amplify';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import reportWebVitals from './reportWebVitals';
import env from './utils/env';
import App from './App';

declare global {
  interface Window {
    google: any;
  }
}

Sentry.init({
  dsn: env('FUTURE_SENTRY_DSN'),
  integrations: [new BrowserTracing()],
  environment: env('FUTURE_ENV'),
  tracesSampleRate: env('FUTURE_ENV') === 'production' ? 0.5 : 1.0,
});

Amplify.configure({
  Auth: {
    identityPoolId: env('FUTURE_AWS_IDENTITY_POOL_ID'),
    region: env('FUTURE_AWS_REGION'),
  },
  Storage: {
    AWSS3: {
      bucket: env('FUTURE_AWS_BUCKET_NAME'),
      region: env('FUTURE_AWS_REGION'),
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

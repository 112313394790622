import * as React from 'react';
import { isEmpty } from 'ramda';
import { Input } from '../..';

type Answer = {
  questionKey: string;
  answers: Array<string>;
};

type CustomHeightInputBuilderProps = {
  id: string;
  label: string;
  value: string;
  error?: string;
  notice?: string;
  onInputChange: (answers: Answer) => void;
};

function CustomHeightInputBuilder({
  id,
  label,
  value,
  error,
  notice,
  onInputChange,
}: CustomHeightInputBuilderProps) {
  const [heightValues, setHeightValues] = React.useState(() => {
    if (value) {
      const values = value.split(' ');
      const feet = values[0];
      const inches = values[1];

      return {
        feet: feet.replace(/[^0-9]/g, ''),
        inches: inches.replace(/[^0-9]/g, ''),
      };
    }

    return {
      feet: '',
      inches: '',
    };
  });

  const handleInputsChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { value: elValue, name } = event.target;

    if (!elValue.match('^[0-9]*$')) {
      return;
    }

    setHeightValues(height => {
      return { ...height, [name]: elValue };
    });

    const changedHeights = Object.values({ ...heightValues, [name]: elValue });

    if (changedHeights && changedHeights.every(height => !isEmpty(height))) {
      const feet = parseInt(changedHeights[0], 10);
      const inches = parseInt(changedHeights[1], 10);

      const height = `${feet}ft ${inches}${inches > 1 ? 'inches' : 'inch'}`;

      onInputChange({ questionKey: id, answers: [height] });
    } else {
      onInputChange({ questionKey: id, answers: [''] });
    }
  };

  return (
    <div>
      <div className="flex flex-col mb-3">
        <label htmlFor={id} className="leading-8 font-futuristagothic text-future-black text-2xl">
          {label}
        </label>
        {notice && <span className="text-xs text-future-deep-gray mt-1 font-medium">{notice}</span>}
      </div>
      <div className="grid grid-cols-2 gap-6 tablet:gap-8 laptop:gap-10">
        <Input
          className="px-1 py-1 border-[#303030] border-0 border-b-[1.2px] rounded-none focus:border-0
          focus:border-b-[1.5px] focus:ring-0 text-center"
          name="feet"
          placeholder="ft"
          value={heightValues.feet}
          onChange={handleInputsChange}
          error={Boolean(error)}
          maxLength={3}
        />

        <Input
          className="px-1 py-1 border-[#303030] border-0 border-b-[1.2px] rounded-none focus:border-0 
        focus:border-b-[1.5px] focus:ring-0 text-center"
          name="inches"
          placeholder="in"
          value={heightValues.inches}
          onChange={handleInputsChange}
          error={Boolean(error)}
          maxLength={3}
        />
      </div>

      {error && <span className="text-future-red text-left text-xs mt-2 inline-flex">{error}</span>}
    </div>
  );
}

CustomHeightInputBuilder.defaultProps = {
  notice: undefined,
  error: undefined,
};

export default CustomHeightInputBuilder;

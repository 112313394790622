const formatPhoneNumber = (phoneNumberString: string) => {
  if (!phoneNumberString) {
    return phoneNumberString;
  }
  const phone = `${phoneNumberString}`.replace(/\D/g, '');
  const match = phone.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return phoneNumberString;
};

export default formatPhoneNumber;

import React from 'react';
import { Button, Modal } from '../..';
import AtomicHeart from '../../../assets/images/image_FUTURE_atomic_heart.png';

type ModalProps = {
  open: boolean;
  onCloseModal: () => void;
};

function EmergencyNotice({ onCloseModal, open }: ModalProps) {
  return (
    <Modal onClose={onCloseModal} show={open}>
      <div className="rounded-xl bg-white tablet:w-[400px]">
        <div className="flex flex-col p-8 pt-8 pb-10 items-center text-center">
          <span className="w-24 h-24 rounded-full flex">
            <img src={AtomicHeart} className="w-24 h-24 self-center mx-auto" alt="emergency call" />
          </span>
          <p className="text-future-black text-sm mb-2 font-normal mt-6">
            Please call{' '}
            <a className="text-future-black" href="tel:911">
              911{' '}
            </a>{' '}
            or{' '}
            <a className="text-future-black" href="tel:988">
              988{' '}
            </a>
            immediately. You can also go to your local emergency department
          </p>

          <div className="mt-12 tablet:mt-5">
            <Button label="Okay" onClick={onCloseModal} className="!w-[210px]" />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default EmergencyNotice;

import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Icon, Image, OptionsMenu, OptionButton, OptionList, OptionItem } from '..';
import { ReactComponent as RiverLogo } from '../../assets/images/image_future_logo.svg';
import { AuthUser } from '../../context/authContext';
import { paths } from '../../routes';
import formatPhoneNumber from '../../utils/formatPhoneNumber';
import ActivateCardPrompt from '../Modal/ActivateCardPrompt/ActivateCardPrompt';
import getFromLocalStorage from '../../utils/getFromLocalStorage';

type NavigationProps = {
  user: AuthUser | null;
  onSignOut: () => void;
};

function Navigation({ user, onSignOut }: NavigationProps) {
  const [showActivateCardModal, setShowActivateCardModal] = React.useState(false);
  const [authCallbackParams] = React.useState(() =>
    getFromLocalStorage('_river_future_auth_callbacks'),
  );

  const params = new URLSearchParams(authCallbackParams);

  const homeURL = params.get('h');

  const accountManageURL = `https://${homeURL}${params.get('am')}`;

  const navigate = useNavigate();
  const location = useLocation();

  const paramsT = new URLSearchParams(location.search);

  return (
    <>
      <header
        className="bg-white shadow-sm  border-[0.5px] border-[#e9ecef] ring-[#e9ecef] ring-opacity-5 fixed top-0
        inset-x-0 z-50 flex-none min-h-[80px] tablet:min-h-[80px]"
      >
        <nav className="px-5 tablet:px-10 desktop:px-16">
          <div className="flex items-center justify-between h-20 tablet:h-24">
            <div className="flex items-center">
              <Link to={paths.HOME_URL_PATH} className="shrink-0">
                <RiverLogo className="w-[200px] h-12" />
              </Link>
            </div>
            <div className="">
              <div className="ml-4 flex items-center space-x-8 tablet:space-x-10">
                <div className="ml-3 relative">
                  <OptionsMenu>
                    <OptionButton className="max-w-xs flex items-center">
                      <Image
                        className="h-[50px] w-[50px] tablet:h-12 tablet:w-12 rounded-full tablet:mr-3"
                        src={user?.signedAvatar || 'image_FUTURE_crystal_ball.png'}
                        alt="user avatar"
                        bgColor={user?.avatarPlaceholderColor || ''}
                      />
                      <span className="hidden tablet:inline-flex">
                        {user?.firstName || user?.lastName
                          ? `${user?.firstName} ${user?.lastName}`
                          : user?.phoneNumber
                          ? formatPhoneNumber(user?.phoneNumber)
                          : ''}
                      </span>
                      <Icon id="arrowDown" className="h-5 w-5 inline-flex" />
                    </OptionButton>
                    <OptionList
                      className="!min-w-[200px] mt-3 right-0 rounded-xl shadow-[0_0px_15px_0px_rgba(0,0,0,0.08)]
                      bg-white border-[0.5px] border-[#e9ecef] ring-[#e9ecef] ring-opacity-5 focus:outline-none"
                    >
                      <OptionItem
                        className="text-future-deep-gray"
                        onClick={() => navigate(paths.PROFILE_URL_PATH)}
                      >
                        Profile
                      </OptionItem>

                      <OptionItem
                        className="text-future-deep-gray"
                        onClick={() => window.parent.location.replace(accountManageURL)}
                      >
                        Manage Account
                      </OptionItem>

                      <OptionItem
                        className="text-future-deep-gray"
                        onClick={() => setShowActivateCardModal(true)}
                      >
                        Request Virtual Card
                      </OptionItem>

                      <OptionItem className="text-future-deep-gray" onClick={onSignOut}>
                        Logout
                      </OptionItem>
                    </OptionList>
                  </OptionsMenu>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>

      {user && (showActivateCardModal || location.pathname === paths.ACTIVATE_CARD_URL_PATH) && (
        <ActivateCardPrompt
          onCloseModal={() => {
            if (paramsT.get('w') && paramsT.get('w') === 'close') {
              window.close();
              return;
            }

            if (location.pathname === paths.ACTIVATE_CARD_URL_PATH) {
              navigate(paths.HOME_URL_PATH);
              return;
            }
            setShowActivateCardModal(false);
          }}
          user={user}
        />
      )}
    </>
  );
}

export default Navigation;

import * as React from 'react';
import { AutoSizeTextBoxInput, Icon } from '..';

type ChatInputProps = {
  message: string;
  onMessageChange: (message: string) => void;
  onFileUpload: (file: File) => void;
  onSendMessage: () => void;
};

function ChatInput({ message, onMessageChange, onFileUpload, onSendMessage }: ChatInputProps) {
  const handleUploadedFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      onFileUpload(files[0]);
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    }
  };

  return (
    <div className="flex items-center space-x-5 tablet:space-x-5 w-full">
      <div className="relative flex items-center flex-auto">
        <AutoSizeTextBoxInput
          className="pr-12 rounded-3xl text-[15px]"
          name="messageInput"
          placeholder="Your message..."
          value={message}
          onChange={event => onMessageChange(event.target.value)}
          onKeyDown={event => {
            if (event.key === 'Enter' && event.ctrlKey) {
              onMessageChange(`${message}\r\n`);
              return;
            }

            if (event.key === 'Enter') {
              event.preventDefault();
              onSendMessage();
            }
          }}
        />
        <Icon
          id="sendMsg"
          className="inline-flex absolute right-3 h-[17px] w-[17px] 
          cursor-pointer fill-[#B3B3B3] hover:fill-river-blue"
          onClick={onSendMessage}
        />
      </div>
      <button
        type="button"
        className="flex-none rounded-full border border-gray-300 w-10 h-10 flex items-center justify-center hover:opacity-80 cursor-pointer"
      >
        <input
          type="file"
          accept="image/*,.doc,.docx,.pdf"
          className="absolute rounded-full w-10 h-10 opacity-0 file:cursor-pointer"
          onChange={handleUploadedFile}
        />
        <Icon
          id="iconMessageTimestampAttached"
          className="w-4 h-4 fill-[#C2C2C2] stroke-white stroke-[0.5px] cursor-pointer"
        />
      </button>
    </div>
  );
}

ChatInput.defaultProps = {};

export default ChatInput;

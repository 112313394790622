import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, MarkdownHTML } from '../../components';
import { ReactComponent as RiverLogo } from '../../assets/images/image_future_logo.svg';
import IconEmojiHi from '../../assets/images/image_FUTURE_Hi.png';
import { paths } from '../../routes';
import env from '../../utils/env';

function NotFound() {
  const navigate = useNavigate();

  return (
    <div className="h-auto bg-river-background flex flex-col">
      <header
        className="bg-white text-future-black shadow-sm  border-[0.5px] 
        border-[#e9ecef] ring-[#e9ecef] ring-opacity-5 fixed top-0 inset-x-0 z-50 flex-none min-h-[80px] tablet:min-h-[80px]"
      >
        <nav className="px-5 tablet:px-10 desktop:px-16">
          <div className="flex items-center justify-between h-20 tablet:h-24">
            <div className="flex items-center">
              <Link to={paths.HOME_URL_PATH} className="shrink-0">
                <RiverLogo className="w-[200px] h-12" />
              </Link>
            </div>

            <div className="" />
          </div>
        </nav>
      </header>
      <main className="relative flex-auto pt-20 tablet:pt-24 px-7 tablet:px-10 desktop:px-16 h-full">
        <div className="relative pt-8 tablet:pt-14 pb-14 flex flex-col justify-center items-center">
          <h3 className="font-medium mb-2 text-future-black font-futuristagothic text-[30px]">
            Hmm. Are we lost?
          </h3>
          <span>
            <pre className="inline-flex text-future-deep-gray">
              {`Looks like you're lost. Don't worry lets take you home.`}
            </pre>
          </span>

          <img src={IconEmojiHi} alt="error" className="w-[150px] tablet:w-[150px]" />

          <Button
            label="Go Home"
            onClick={() => navigate(paths.HOME_URL_PATH)}
            className="!px-2 mt-10 !w-[210px]"
          />

          <MarkdownHTML
            className="text-future-black text-xs mt-6 text-center"
            content={`If this persists, please reach out to our [support team.](${env(
              'FUTURE_APP_URL',
            )}/support)`}
          />
        </div>
      </main>
    </div>
  );
}

export default NotFound;

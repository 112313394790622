import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { IconSprite } from '../Icon/Icon';

function ClinicsLoader() {
  return (
    <>
      {new Array(8).fill(null).map((_, index) => (
        <div
          className="bg-white rounded-2xl border border-neutral-300/30 px-5 py-8 flex flex-row space-x-8"
          key={index}
        >
          <div>
            <Skeleton circle height={90} width={90} />
          </div>

          <div className="w-full">
            <div>
              <Skeleton
                width="80%"
                height={18}
                className="!text-[10px] !leading-none
                !px-[10px] !py-[5px] !inline-block"
              />
              <Skeleton width="70%" height={19} />
            </div>

            <div className="text-xs leading-relaxed text-[#626161] space-y-1 mt-1">
              <p>
                <IconSprite
                  id="icon-star"
                  className="w-[13.5px] h-[13.5px] mr-1 inline-flex shrink-0 opacity-30"
                />
                <Skeleton width="60%" />
              </p>
              <p>
                <IconSprite
                  id="icon-phone"
                  className="w-[15px] h-[15px] mr-1 inline-flex shrink-0 opacity-30"
                />
                <Skeleton width="60%" />
              </p>
              <p className="block">
                <Skeleton width="80%" />
              </p>
            </div>

            <div className="text-[15px] mt-3">
              <Skeleton width="80%" />
            </div>

            <button
              type="button"
              className="!mt-4  text-[13px] leading-none rounded-3xl text-river-blue
    border border-river-blue px-[15px] py-[15px] tablet:py-[10px] inline-block cursor-not-allowed shrink-0 opacity-30"
            >
              Book appointment
            </button>
          </div>
        </div>
      ))}
    </>
  );
}

export default ClinicsLoader;

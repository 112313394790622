/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import axios from 'axios';
import { isEmpty } from 'ramda';
import moment from 'moment-timezone';
import { NavigateFunction } from 'react-router-dom';
import env from '../utils/env';
import apigateway from '../utils/apigateway';
import { formatErrors, transformResponse } from '../utils/request';
import { paths } from '../routes';
import setToLocalStorage, { setToSessionStorage } from '../utils/setToLocalStorage';
import getFromLocalStorage from '../utils/getFromLocalStorage';
import { hasStateMatchingZipCode } from '../utils/getStateFromZipCode';
import { Answer, Note, QuestionAnswers, listenToLatestVisit } from './visitContext';
import { reportUserIdentityToSentry, clearCurrentUserOnSentry } from '../utils/reportToSentry';
import US_STATES from '../utils/usStates';

axios.defaults.baseURL = env('FUTURE_API_URL');

export type AuthToken = {
  userId: string;
  email: string;
  phoneNumber: string;
  expiresAt: string;
  token: string;
};

export type AuthUser = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  preferredPronouns: string;
  preferredName: string;
  employeeId: string;
  profilePicture: string;
  idImage: string;
  selfieImage: string;
  signedAvatar: string;
  avatarPlaceholderColor: string;
  description?: string;
  streetAddress?: string;
  apartment?: string;
  city?: string;
  state?: string;
  zipCode: string;
  brokerCode?: string;
  patientDetails?: {
    height?: number;
    weight?: number;
    emergencyContactFirstName?: string;
    emergencyContactLastName?: string;
    emergencyContactPhoneNumber?: string;
    dateOfBirth?: string;
    gender?: Sex;
    genderIdentity?: Gender;
    allergies?: string;
    currentMedication?: string;
    medicalHistory?: Array<Answer>;
    patientLabTests?: any;
    guides?: AssignedProvider[];
    extendedNotes?: Array<Note>;
    planType?: string;
    subscriptionType?: string;
    planFamilyType?: string;
  };
  createdAt?: string;
  updatedAt?: string;
  customerId?: string;
  coupon?: string;
  unreadCount?: number;
  subscription: AuthSubscription;
  latestVisit: QuestionAnswers | null;
  coordinates?: {
    latitude: number;
    longitude: number;
  };
  companies?: Array<Company>;
  intercomHmac?: string;
  insurance: {
    bin: string;
    cardBackUrl: string;
    cardFrontUrl: string;
    memberId: string;
    subscriberId: string;
    memberName: string;
    pcn: string;
    planName: string;
    provider: string;
    rxGroup: string;
  };
};

export type AuthSubscription = {
  availableSubscriptionPlans: SubscriptionPlan[];
  currentSubscriptionPlan: SubscriptionPlan;
  currentSubscriptionPlans: SubscriptionPlan[];
  expirationDate: string;
  last4Digits: string;
  postalCode: string;
};

export type SubscriptionPlan = {
  currency: string;
  id: string;
  monthlyPrice: number;
  monthlyPriceWithDiscount: number;
  name: string;
  nextChargeDate: string;
  trialDaysLeft: number;
};

export type AssignedProvider = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  profilePicture: string;
  signedAvatar: string;
  avatarPlaceholderColor: string;
  description: string;
  zipCode: string | number;
  roles?: Array<'CARE-HEALTH-GUIDE' | 'MENTAL-HEALTH-GUIDE'>;
  isAvailable: boolean;
  isAcceptingVisit: boolean;
  createdAt?: string;
  updatedAt?: string;
};

export type Sex = 'MALE' | 'FEMALE' | 'INTERSEX';

export type Gender =
  | 'MALE'
  | 'FEMALE'
  | 'NON-BINARY'
  | 'TRANSGENDER-MALE'
  | 'TRANSGENDER-FEMALE'
  | 'TRANSGENDER';

type Company = {
  name: string;
  amountCoveredByEmployer: number;
  amountCoveredByEmployerPrimaryCare: number;
  amountCoveredByEmployerCompleteCare: number;
  amountCoveredByEmployerStarterCare: number;
  discounts: any;
  isOfferingPayrollDeduction: boolean;
  familyPlanTypeformId: string;
  isOfferingPrimaryCare: boolean;
  isOfferingStarterCare: boolean;
  isOfferingCompleteCare: boolean;
  planPaymentOption: string;
};

type Alert = {
  message: string;
  type: 'SUCCESS' | 'ERROR' | 'WARNING' | 'INFO';
  show: boolean;
};

type AuthState = {
  user: AuthUser | null;
  status: 'IDLE' | 'LOADING' | 'RESOLVED' | 'REJECTED';
  alert: Alert;
  virtualClinicStatus: 'OPENED' | 'CLOSED' | 'UNAVAILABLE' | 'UNDER_MAINTENANCE';
  modalState: 'CLOSED' | 'OPENED';
};

type AuthDispatch = (action: AuthAction) => void;

type AuthAction =
  | { type: 'SET_AUTH_USER'; payload: AuthUser | null }
  | {
      type: 'SET_AUTH_STATUS';
      payload: 'IDLE' | 'LOADING' | 'RESOLVED' | 'REJECTED';
    }
  | { type: 'SET_MODAL_STATE'; payload: 'CLOSED' | 'OPENED' }
  | {
      type: 'SET_VIRTUAL_CLINIC_STATUS';
      payload: 'OPENED' | 'CLOSED' | 'UNAVAILABLE' | 'UNDER_MAINTENANCE';
    }
  | { type: 'SHOW_ALERT'; payload: Alert };

const initialState: AuthState = {
  user: null,
  status: 'IDLE',
  virtualClinicStatus: 'OPENED',
  alert: { message: '', type: 'INFO', show: false },
  modalState: 'CLOSED',
};

const AuthContext = React.createContext<[state: AuthState, dispatch: AuthDispatch] | undefined>(
  undefined,
);

AuthContext.displayName = 'AuthContext';

function authReducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case 'SET_AUTH_USER':
      return {
        ...state,
        user: action.payload,
      };
    case 'SET_AUTH_STATUS':
      return {
        ...state,
        status: action.payload,
      };
    case 'SHOW_ALERT':
      return {
        ...state,
        alert: action.payload,
      };
    case 'SET_VIRTUAL_CLINIC_STATUS':
      return {
        ...state,
        virtualClinicStatus: action.payload,
      };
    case 'SET_MODAL_STATE':
      return {
        ...state,
        modalState: action.payload,
      };
    default:
      return state;
  }
}

function AuthProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = React.useReducer(authReducer, initialState);

  const value = React.useMemo(() => [state, dispatch], [state, dispatch]) as [
    state: AuthState,
    dispatch: AuthDispatch,
  ];

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`\`useAuth\` must be used within a AuthProvider`);
  }
  return context;
}

export async function syncAuthUser(dispatch: AuthDispatch) {
  dispatch({ type: 'SET_AUTH_STATUS', payload: 'LOADING' });

  try {
    const storedUser = JSON.parse(getFromLocalStorage('_river_future_usrid') || '{}') as AuthUser; // For now we get subscription data here

    const authUser = (await apigateway.get(`/user/profile`)) as any;

    const visits = await listenToLatestVisit();

    const mergedAuthUser = {
      ...authUser,
      latestVisit: visits,
      subscription: storedUser.subscription,
    } as AuthUser;

    dispatch({ type: 'SET_AUTH_STATUS', payload: 'RESOLVED' });

    setToLocalStorage('_river_future_usrid', JSON.stringify(mergedAuthUser));

    dispatch({ type: 'SET_AUTH_USER', payload: mergedAuthUser });
  } catch (error) {
    dispatch({ type: 'SET_AUTH_STATUS', payload: 'IDLE' });
  }
}

export function showAlert(
  dispatch: AuthDispatch,
  { show, type, message } = {} as Alert,
  autoDismiss = false,
) {
  dispatch({
    type: 'SHOW_ALERT',
    payload: { show, type, message },
  });

  if (autoDismiss) {
    setTimeout(
      () =>
        dispatch({
          type: 'SHOW_ALERT',
          payload: { show: false, type: 'INFO', message: '' },
        }),
      3000,
    );
  }
}

export function signOut(callbackParams?: string) {
  const authCallbackParams = getFromLocalStorage('_river_future_auth_callbacks') || callbackParams;
  const params = new URLSearchParams(authCallbackParams);
  const homeURL = params.get('h');
  const logoutURL = params.get('lo');

  sessionStorage.removeItem('_river_future_tokid');
  localStorage.removeItem('_river_future_usrid');
  localStorage.removeItem('_river_future_enroll_completed_modal_closed');
  localStorage.removeItem('_river_future_auth_callbacks');

  clearCurrentUserOnSentry();

  if (homeURL && logoutURL) {
    window.parent.location.replace(`https://${homeURL}${logoutURL}`);
    return;
  }

  window.parent.location.replace(env('FUTURE_HOME_URL') || '');
}

export async function signIn(
  dispatch: AuthDispatch,
  navigate: NavigateFunction,
  token: string,
  fromPath: string,
  authCallbacksParams: string,
) {
  try {
    dispatch({ type: 'SET_AUTH_STATUS', payload: 'LOADING' });

    let destinationPath = fromPath;

    const authToken = (await apigateway.post('/auth/future', {
      token,
    })) as AuthToken;

    const {
      data: { data: authUserData },
    } = await getAuthUser(authToken.token);

    const authUser = authUserData as AuthUser;

    const emptyPersonalDetails =
      isEmpty(authUser.firstName) && isEmpty(authUser.lastName) && isEmpty(authUser.email);

    if (
      emptyPersonalDetails ||
      isEmpty(authUser.zipCode) ||
      isEmpty(authUser.state) ||
      !hasStateMatchingZipCode(authUser.state, authUser.zipCode)
    ) {
      showAlert(dispatch, {
        show: true,
        message: 'Kindly provide us with your basic information before you continue.',
        type: 'INFO',
      });

      destinationPath = paths.PROFILE_URL_PATH;
    }

    const {
      data: { data: authSubscriptionData },
    } = await getAuthSubscription(authToken.token);

    const authSubscription = authSubscriptionData as AuthSubscription;

    const plan = getAvailablePlan(authSubscription, '', authUser);

    const subscriptionStatus = getOngoingSubscriptionStatus(
      authSubscription.currentSubscriptionPlan,
    );

    if (subscriptionStatus === 'NO_SUBSCRIPTION') {
      dispatch({ type: 'SET_AUTH_STATUS', payload: 'REJECTED' });
      showAlert(dispatch, {
        show: true,
        message: `Sorry! Looks like you're not on any subscription plan.`,
        type: 'ERROR',
      });
      return;
    }

    if (subscriptionStatus === 'EXPIRED_SUBSCRIPTION') {
      dispatch({ type: 'SET_AUTH_STATUS', payload: 'REJECTED' });
      showAlert(dispatch, {
        show: true,
        message: `Sorry! Your "${plan.name}", subscription has expired. Please renew!`,
        type: 'ERROR',
      });
      return;
    }

    const mergedAuthUser = {
      ...authUser,
      subscription: {
        availableSubscriptionPlans: authSubscription.availableSubscriptionPlans,
        currentSubscriptionPlan: authSubscription.currentSubscriptionPlan,
        currentSubscriptionPlans: authSubscription.currentSubscriptionPlans,
        expirationDate: authSubscription.expirationDate,
        last4Digits: authSubscription.last4Digits,
        postalCode: authSubscription.postalCode,
      },
    } as AuthUser;

    setToSessionStorage('_river_future_tokid', JSON.stringify(authToken));

    setToLocalStorage('_river_future_usrid', JSON.stringify(mergedAuthUser));

    setToLocalStorage('_river_future_auth_callbacks', authCallbacksParams);

    subscribeToExternals(authUser);

    dispatch({ type: 'SET_AUTH_STATUS', payload: 'RESOLVED' });

    dispatch({ type: 'SET_AUTH_USER', payload: mergedAuthUser });

    if (!isEmpty(destinationPath)) {
      navigate(destinationPath);
      return;
    }

    navigate(paths.HOME_URL_PATH, { replace: true });
  } catch (error) {
    dispatch({ type: 'SET_AUTH_STATUS', payload: 'REJECTED' });

    const { config, message, status } = formatErrors(error);

    showAlert(dispatch, {
      show: true,
      message,
      type: 'ERROR',
    });
  }
}

function getAuthUser(token: string) {
  return axios({
    method: 'get',
    url: `/user/profile`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    transformResponse,
  });
}

function getAuthSubscription(token: string) {
  return axios({
    method: 'get',
    url: `/billing/subscription?stripe_api_version=${env('FUTURE_STRIPE_VERSION')}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    transformResponse,
  });
}

function getOngoingSubscriptionStatus(
  plan: SubscriptionPlan,
): 'NO_SUBSCRIPTION' | 'EXPIRED_SUBSCRIPTION' | 'HAS_SUBSCRIPTION' {
  if (!plan || !plan.id) {
    return 'NO_SUBSCRIPTION';
  }

  const nextChargeDate = moment(plan.nextChargeDate).startOf('day');
  if (
    (!nextChargeDate.isValid() || nextChargeDate.diff(moment().startOf('day'), 'days', true) < 0) &&
    plan?.monthlyPriceWithDiscount !== 0
  ) {
    return 'EXPIRED_SUBSCRIPTION';
  }

  return 'HAS_SUBSCRIPTION';
}

function getAvailablePlan(
  subscription: AuthSubscription,
  possiblePlan: string,
  user?: AuthUser,
): SubscriptionPlan {
  let plan: SubscriptionPlan | undefined;

  let company = null;

  if (user) {
    company = getCompany(user);
  }

  if (possiblePlan === 'complete' || (!possiblePlan && company?.isOfferingCompleteCare)) {
    plan = subscription.availableSubscriptionPlans.find(
      availablePlan => availablePlan.id === env('FUTURE_COMPLETE_MONTHLY_SUB_PLAN_ID'),
    );
  } else if (possiblePlan === 'primary' || (!possiblePlan && company?.isOfferingPrimaryCare)) {
    plan = subscription.availableSubscriptionPlans.find(
      availablePlan => availablePlan.id === env('FUTURE_PRIMARY_MONTHLY_SUB_PLAN_ID'),
    );
  } else if (possiblePlan === 'virtual') {
    plan = subscription.availableSubscriptionPlans.find(
      availablePlan => availablePlan.id === env('FUTURE_VIRTUAL_MONTHLY_SUB_PLAN_ID'),
    );
  } else if (possiblePlan === 'starter') {
    plan = subscription.availableSubscriptionPlans.find(
      availablePlan => availablePlan.id === env('FUTURE_STARTER_MONTHLY_SUB_PLAN_ID'),
    );
  } else if (company?.isOfferingPrimaryCare || company?.isOfferingCompleteCare) {
    plan = subscription.availableSubscriptionPlans.find(
      availablePlan => availablePlan.id === env('FUTURE_PRIMARY_MONTHLY_SUB_PLAN_ID'),
    );

    if (!plan) {
      plan = subscription.availableSubscriptionPlans.find(
        availablePlan => availablePlan.id === env('FUTURE_COMPLETE_MONTHLY_SUB_PLAN_ID'),
      );
    }
  }

  if (!plan) {
    plan = subscription.availableSubscriptionPlans.find(
      availablePlan => availablePlan.id === env('FUTURE_STARTER_MONTHLY_SUB_PLAN_ID'),
    );
  }

  return plan as SubscriptionPlan;
}

export function locatedInANonSupportedState(state: string) {
  return ['South Carolina', 'Alaska', 'Arkansas', 'Hawaii', 'Missouri'].includes(
    US_STATES.find(fndState => fndState.value === state)?.label || '',
  );
}

export function hasCompletedAllRequiredProfileInfo(user: AuthUser) {
  return (
    user?.firstName &&
    user?.lastName &&
    user.email &&
    user?.patientDetails?.dateOfBirth &&
    user.patientDetails?.gender &&
    user?.patientDetails?.height &&
    user?.patientDetails?.weight &&
    user?.idImage
  );
}

export function hasACompletedMedicalHistoryInfo(user: AuthUser) {
  const hasAllergiesAndMedication = Boolean(
    user?.patientDetails?.medicalHistory?.find(
      fndMed => fndMed.questionKey === 'CURRENT_MEDICATION_OR_SUPPLEMENTS',
    ) &&
      user?.patientDetails?.medicalHistory?.find(
        fndMed => fndMed.questionKey === 'CURRENT_ALLERGIES',
      ),
  );

  return (
    user?.patientDetails?.medicalHistory &&
    user?.patientDetails?.medicalHistory?.length > 0 &&
    hasAllergiesAndMedication
  );
}

export function getCompany(user: AuthUser): Company | null {
  let company = null;

  if (user?.companies && user?.companies?.length > 0) {
    [company] = user.companies;
  }

  return company;
}

export function getSubscribedPlan(user?: AuthUser | null) {
  const subscriptionType = user?.patientDetails?.subscriptionType;

  if (subscriptionType === 'COMPLETE-CARE' || subscriptionType === 'STARTER-CARE') {
    return user?.subscription?.currentSubscriptionPlan?.name || 'Primary Care Plan';
  }

  if (subscriptionType === 'VIRTUAL-CARE') {
    return 'Virtual Plan';
  }

  return 'Primary Care Plan';
}

function subscribeToExternals(authUser: AuthUser) {
  reportUserIdentityToSentry({
    id: authUser.id,
  });
}

export { AuthProvider, useAuth };

import React from 'react';
import { MarkdownHTML, Modal } from '../..';
import { AuthUser } from '../../../context/authContext';

type ModalProps = {
  user: AuthUser;
  onCloseModal: () => void;
};

function ActivateCardPrompt({ onCloseModal, user }: ModalProps) {
  const instructions = [
    'Complete this [Virtual Card Request Form](https://team008418.typeform.com/futurehealth). The request should be made as soon as an in-person appointment is scheduled.',
    'You’ll be issued a virtual payment card via secure email to your email address on file.',
    'The secure email will be sent the morning of your appointment and will expire within 24 hours.',
    'Use the virtual card to pay for your in-person visit.',
    'Email your receipt to team@helloriver.com',
  ];

  // const instructions = [
  //   'Call the number located on the sticker of your River Health Card and follow the prompts to activate your card.',
  //   'Enter the 16-digit number found on the front of your card.',
  //   'Enter the last four digits of your Member ID. Your Member ID can be found on the bottom of this screen.',
  //   'Enter the three-digit CVV code. The CVV code is the last three digits of the number located on the signature panel on the back of your card.',
  //   'Creating a PIN number for you card is optional.',
  // ];

  return (
    <Modal onClose={onCloseModal} show>
      <div className="rounded-xl bg-white tablet:w-[600px] laptop:w-[800px]">
        <div className="flex flex-col px-6 tablet:px-12 py-7 pb-9 items-center text-future-black">
          <h3
            className="mb-4 inline-flex text-center items-center
            font-futuristagothic text-2xl tablet:text-[30px]"
          >
            Request Virtual Card
          </h3>

          <div
            className="bg-[#E8FFD6] rounded-xl text-xs px-7 py-6 mx-auto flex 
            justify-center items-center text-left"
          >
            FUTURE Health has partnered with River Health to give you access to in-person doctor
            visits at over 20,000 clinics across the country.
            <br />
            <br />
            As a FUTURE member, you’ll be issued a virtual River Health debit card for one-time use.
            You’ll need to request this as soon as you schedule an in-person appointment.
            <br />
            <br />
            Once activated, use the card to pay for your in-person visit. It will guarantee the $99
            FUTURE member rate, and your card on file will be charged.
          </div>

          {/* <div
            className="bg-[#E8FFD6] rounded-xl text-xs px-7 py-6 mx-auto flex 
            justify-center items-center text-left"
          >
            FUTURE Health has partnered with River Health to give you access to in-person doctor
            visits at over 20,000 clinics across the country.
            <br />
            <br />
            As a FUTURE member, you’ll receive a River Health debit card in the mail at your address
            on file within 7-10 business days. <br />
            <br />
            Once activated, use this card to pay for in-person visits within the partner network. It
            will guarantee the $99 FUTURE member rate, and your card on file will be charged.
          </div> */}

          <div className="mt-8 flex flex-col space-y-5 tablet:space-y-6">
            {instructions.map((notice, index) => (
              <div
                className="flex flex-row items-center space-x-4 border border-future-black py-4 rounded-xl px-4"
                key={index}
              >
                <span
                  className="bg-black rounded-full w-8 h-8 flex items-center justify-center text-white
                  font-futuristagothic text-xl flex-shrink-0"
                >
                  <span className="-top-[2px] relative">{index + 1}</span>
                </span>
                <MarkdownHTML className="text-sm text-left text-future-black" content={notice} />
              </div>
            ))}
          </div>

          <div className="mt-7 text-lg tablet:text-[20px]">
            Member ID {user?.insurance?.subscriberId || 'N/A'}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ActivateCardPrompt;

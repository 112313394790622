import * as React from 'react';
import { AutoSizeTextBoxInput } from '../..';

type Answer = {
  questionKey: string;
  answers: Array<string>;
};

type TextBoxInputBuilderProps = {
  id: string;
  name: string;
  label: string;
  value: string;
  error?: string;
  notice?: string;
  onInputChange: (answers: Answer) => void;
};

function TextBoxInputBuilder({
  id,
  label,
  value,
  error,
  notice,
  name,
  onInputChange,
}: TextBoxInputBuilderProps) {
  const [textValue, setTextValue] = React.useState(value);

  const handleInputsChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextValue(event.target.value);
    onInputChange({
      questionKey: id,
      answers: [event.target.value],
    });
  };

  return (
    <div>
      <div className="flex flex-col mb-2">
        <label htmlFor={id} className="leading-8 font-futuristagothic text-future-black text-2xl">
          {label}
        </label>
        {notice && <span className="text-xs text-future-deep-gray mt-1 font-medium">{notice}</span>}
      </div>
      <AutoSizeTextBoxInput
        className="fs-exclude px-1 py-1 border-[#303030] border-0 border-b-[1.2px] rounded-none focus:border-0 
        focus:border-b-[1.5px] focus:ring-0"
        name={name}
        value={textValue}
        onChange={handleInputsChange}
        error={Boolean(error)}
      />
      {error && <span className="text-future-red text-left text-xs mt-2 inline-flex">{error}</span>}
    </div>
  );
}

TextBoxInputBuilder.defaultProps = {
  notice: undefined,
  error: undefined,
};

export default TextBoxInputBuilder;

import React from 'react';
import { Button, Modal } from '../..';
import Checkmark from '../../../assets/images/image_FUTURE_checkmark.png';

type ModalProps = {
  onConfirmBooking: () => void;
};

function AppointmentInfoPrompt({ onConfirmBooking }: ModalProps) {
  return (
    <Modal show>
      <div className="rounded-xl bg-white tablet:w-[500px]">
        <div className="flex flex-col px-7 py-7 pb-10 items-center text-center">
          <div className="w-[120px] h-[120px] flex flex-col items-center justify-center">
            <img
              src={Checkmark}
              className="object-center object-cover h-[100px] mx-auto"
              alt="appointment info"
            />
          </div>

          <p className="text-future-black text-sm mt-3">
            Thanks for booking an appointment with FUTURE Health. Contact our support team or your
            primary care provider if you have questions.
          </p>

          <div className="mt-12 tablet:mt-6">
            <Button
              label="Return home"
              onClick={onConfirmBooking}
              className="!bg-secondary-gradient w-[200px]"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AppointmentInfoPrompt;

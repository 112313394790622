/* eslint-disable prettier/prettier */
import * as React from 'react';

export const paths = {
  LOGIN_URL_PATH: '/',
  DOCTOR_LISTINGS_PATH: '/doctor-listings',
  HOME_URL_PATH: '/home',
  PROFILE_URL_PATH: '/profile',
  MEDICAL_HISTORY_URL_PATH: '/profile/medical-history',
  CARE_SERVICES_URL_PATH: '/care/services',
  CARE_HISTORIES_URL_PATH: '/care/histories',
  CARE_HISTORIES_VISIT_URL_PATH: '/care/histories/visit',
  MEDICALS_HISTORIES_VISIT_URL_PATH: '/care/histories/medicals',
  MEDICATIONS_HISTORIES_VISIT_URL_PATH: '/care/histories/medications',
  LABS_HISTORIES_VISIT_URL_PATH: '/care/histories/labs',
  NOTES_HISTORIES_VISIT_URL_PATH: '/care/histories/notes',
  CALLS_HISTORIES_VISIT_URL_PATH: '/care/histories/calls',
  CARE_VISITS_INTRO_URL_PATH: '/care/:careType/visit/intro',
  CARE_VISITS_COMPLETE_URL_PATH: '/care/:careType/visit/complete',
  BIRTH_CONTROL_VISITS_URL_PATH: '/care/birth-control/visit',
  SICK_VISITS_URL_PATH: '/care/sick/visit',
  UTI_VISITS_URL_PATH: '/care/uti/visit',
  VAGINAL_INFECTION_VISITS_URL_PATH: '/care/vaginal-infections/visit',
  ERECTILE_DYSFUNCTION_VISITS_URL_PATH: '/care/erectile-dysfunction/visit',
  MENSTRUAL_VISITS_URL_PATH: '/care/menstrual-irregularities/visit',
  SEXUAL_HEALTH_VISITS_URL_PATH: '/care/sexual-health/visit',
  STD_DIAGNOSIS_VISITS_URL_PATH: '/care/std-diagnosis/visit',
  STD_TEST_VISITS_URL_PATH: '/care/std-test/visit',
  GENITAL_HERPES_VISITS_URL_PATH: '/care/genital-herpes/visit',
  COLD_SORES_VISITS_URL_PATH: '/care/cold-sores/visit',
  RX_REFILLS_VISITS_URL_PATH: '/care/rx-refills/visit',
  HAIR_SKIN_VISITS_URL_PATH: '/care/hair-skin/visit',
  BEHAVIORAL_HEALTH_VISITS_URL_PATH: '/care/mental-health/visit',
  VIEW_LAB_RESULT_PATH: '/lab-result/:labTestId',
  CLINIC_BOOKING_URL_PATH: '/clinic/setup-appointment',
  SICK_VISIT_CLINIC_BOOKING_URL_PATH: '/clinic/sick/setup-appointment',
  MANUAL_CLINIC_BOOKING_URL_PATH: '/clinic/setup-appointment-manually',
  CONFIRM_BOOKING_URL_PATH: '/clinic/confirm-appointment',
  VIDEO_CALL_INTRO_URL_PATH: '/virtual-visit/video-call/intro',
  VIDEO_CALL_PATH: '/virtual-visit/video-call',
  VIRTUAL_VISIT_CVS_CARE_PATH: '/virtual-visit/cvs-care',
  CVS_CLINIC_PASS_PATH: '/cvs-clinic-pass',
  ALLINA_CLINIC_PASS_PATH: '/allina-clinic-pass',
  FAMILY_PLAN_PATH: '/add-family-plan',
  SUPPORT_URL_PATH: '/support',
  ACTIVATE_CARD_URL_PATH: '/activatecard',
  FOLLOW_UP_URL_PATH: '/follow-up',
};

const routes = [
  {
    path: paths.LOGIN_URL_PATH,
    private: false,
    component: React.lazy(() => import('./pages/Login/Login')),
  },
  {
    path: paths.DOCTOR_LISTINGS_PATH,
    component: React.lazy(() => import('./pages/DoctorListings/DoctorListings')),
    private: false,
  },
  {
    path: paths.HOME_URL_PATH,
    component: React.lazy(() => import('./pages/Home/Home')),
    private: true,
  },
  {
    path: paths.ACTIVATE_CARD_URL_PATH,
    component: React.lazy(() => import('./pages/Home/Home')), // Done to show the activate card modal
    private: true,
  },
  {
    path: paths.CARE_SERVICES_URL_PATH,
    component: React.lazy(() => import('./pages/CareServices/CareServices')),
    private: true,
  },
  {
    path: paths.CARE_HISTORIES_URL_PATH,
    component: React.lazy(() => import('./pages/CareHistory/CareHistory')),
    private: true,
  },
  {
    path: paths.CARE_HISTORIES_VISIT_URL_PATH,
    component: React.lazy(() => import('./pages/ReviewVisitHistory/ReviewVisitHistory')),
    private: true,
  },
  {
    path: paths.PROFILE_URL_PATH,
    component: React.lazy(() => import('./pages/Profile/Profile')),
    private: true,
  },
  {
    path: paths.MEDICAL_HISTORY_URL_PATH,
    component: React.lazy(() => import('./pages/MedicalHistory/MedicalHistory')),
    private: true,
  },
  {
    path: paths.CARE_VISITS_INTRO_URL_PATH,
    component: React.lazy(() => import('./pages/CareVisitsIntro/CareVisitsIntro')),
    private: true,
  },
  {
    path: paths.CARE_VISITS_COMPLETE_URL_PATH,
    component: React.lazy(() => import('./pages/CareVisitsComplete/CareVisitsComplete')),
    private: true,
  },
  {
    path: paths.BIRTH_CONTROL_VISITS_URL_PATH,
    component: React.lazy(() => import('./pages/CareVisits/BirthControl')),
    private: true,
  },
  {
    path: paths.SICK_VISITS_URL_PATH,
    component: React.lazy(() => import('./pages/CareVisits/SickVisit')),
    private: true,
  },
  {
    path: paths.UTI_VISITS_URL_PATH,
    component: React.lazy(() => import('./pages/CareVisits/UTIVisit')),
    private: true,
  },
  {
    path: paths.VAGINAL_INFECTION_VISITS_URL_PATH,
    component: React.lazy(() => import('./pages/CareVisits/VaginalInfection')),
    private: true,
  },
  {
    path: paths.ERECTILE_DYSFUNCTION_VISITS_URL_PATH,
    component: React.lazy(() => import('./pages/CareVisits/ErectileDysfunction')),
    private: true,
  },
  {
    path: paths.MENSTRUAL_VISITS_URL_PATH,
    component: React.lazy(() => import('./pages/CareVisits/MenstrualIrregularities')),
    private: true,
  },
  {
    path: paths.SEXUAL_HEALTH_VISITS_URL_PATH,
    component: React.lazy(() => import('./pages/CareVisits/SexualHealth/SexualHealthOption')),
    private: true,
  },
  {
    path: paths.STD_DIAGNOSIS_VISITS_URL_PATH,
    component: React.lazy(() => import('./pages/CareVisits/SexualHealth/STIDiagnosis')),
    private: true,
  },
  {
    path: paths.STD_TEST_VISITS_URL_PATH,
    component: React.lazy(() => import('./pages/CareVisits/SexualHealth/STITest')),
    private: true,
  },
  {
    path: paths.GENITAL_HERPES_VISITS_URL_PATH,
    component: React.lazy(() => import('./pages/CareVisits/SexualHealth/GenitalHerpes')),
    private: true,
  },
  {
    path: paths.COLD_SORES_VISITS_URL_PATH,
    component: React.lazy(() => import('./pages/CareVisits/SexualHealth/ColdSores')),
    private: true,
  },
  {
    path: paths.HAIR_SKIN_VISITS_URL_PATH,
    component: React.lazy(() => import('./pages/CareVisits/HairSkin')),
    private: true,
  },
  // {
  //   path: paths.BEHAVIORAL_HEALTH_VISITS_URL_PATH,
  //   component: React.lazy(() => import('./pages/CareVisits/BehavioralHealth')),
  //   private: true,
  // },
  {
    path: paths.RX_REFILLS_VISITS_URL_PATH,
    component: React.lazy(() => import('./pages/CareVisits/RxRefills')),
    private: true,
  },
  {
    path: paths.CLINIC_BOOKING_URL_PATH,
    component: React.lazy(() => import('./pages/ClinicBooking/ClinicBooking')),
    private: true,
  },
  {
    path: paths.SICK_VISIT_CLINIC_BOOKING_URL_PATH,
    component: React.lazy(() => import('./pages/SickVisitClinicBooking/SickVisitClinicBooking')),
    private: true,
  },
  // {
  //   path: paths.MANUAL_CLINIC_BOOKING_URL_PATH,
  //   component: React.lazy(() => import('./pages/ManualClinicBooking/ManualClinicBooking')),
  //   private: true,
  // },
  {
    path: paths.CONFIRM_BOOKING_URL_PATH,
    component: React.lazy(
      () => import('./pages/ClinicBookingConfirmation/ClinicBookingConfirmation'),
    ),
    private: true,
  },
  {
    path: paths.MEDICALS_HISTORIES_VISIT_URL_PATH,
    component: React.lazy(() => import('./pages/CareHistory/HistoryMedicals')),
    private: true,
  },
  {
    path: paths.MEDICATIONS_HISTORIES_VISIT_URL_PATH,
    component: React.lazy(() => import('./pages/CareHistory/HistoryMedications')),
    private: true,
  },
  {
    path: paths.LABS_HISTORIES_VISIT_URL_PATH,
    component: React.lazy(() => import('./pages/CareHistory/HistoryLabs')),
    private: true,
  },
  {
    path: paths.NOTES_HISTORIES_VISIT_URL_PATH,
    component: React.lazy(() => import('./pages/CareHistory/HistoryNotes')),
    private: true,
  },
  {
    path: paths.CALLS_HISTORIES_VISIT_URL_PATH,
    component: React.lazy(() => import('./pages/CareHistory/HistoryCalls')),
    private: true,
  },
  {
    path: paths.VIEW_LAB_RESULT_PATH,
    component: React.lazy(() => import('./pages/LabResult/LabResult')),
    private: true,
  },
  {
    path: paths.VIDEO_CALL_INTRO_URL_PATH,
    component: React.lazy(() => import('./pages/VideoCall/VideoCallInto')),
    private: true,
  },
  {
    path: paths.VIDEO_CALL_PATH,
    component: React.lazy(() => import('./pages/VideoCall/VideoCall')),
    private: true,
  },
  {
    path: paths.CVS_CLINIC_PASS_PATH,
    component: React.lazy(() => import('./pages/ClinicPass/CVSPass/CVSPass')),
    private: true,
  },
  {
    path: paths.ALLINA_CLINIC_PASS_PATH,
    component: React.lazy(() => import('./pages/ClinicPass/AllinaPass/AllinaPass')),
    private: true,
  },
  {
    path: paths.VIRTUAL_VISIT_CVS_CARE_PATH,
    component: React.lazy(() => import('./pages/CVSVirtualCare/CVSVirtualCare')),
    private: true,
  },
  {
    path: paths.SUPPORT_URL_PATH,
    component: React.lazy(() => import('./pages/SupportCenter/SupportCenter')),
    private: true,
  },
  {
    path: paths.FOLLOW_UP_URL_PATH,
    component: React.lazy(() => import('./pages/FollowupVisit/FollowupVisit')),
    private: true,
  },
];

export default routes;

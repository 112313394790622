/* eslint-disable @typescript-eslint/no-shadow */
import * as React from 'react';
import { Input } from '../..';

type Answer = {
  questionKey: string;
  answers: Array<string>;
};

type CustomWeightInputBuilderProps = {
  id: string;
  label: string;
  value: string;
  error?: string;
  notice?: string;
  onInputChange: (answers: Answer) => void;
};

function CustomWeightInputBuilder({
  id,
  label,
  value,
  error,
  notice,
  onInputChange,
}: CustomWeightInputBuilderProps) {
  const [inputValue, setInputValue] = React.useState(value.replace(/[^0-9]/g, ''));

  const handleInputsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (!value.match('^[0-9]*$')) {
      return;
    }

    setInputValue(value);

    onInputChange({ questionKey: id, answers: [`${value}lb`] });
  };

  return (
    <div>
      <div className="flex flex-col mb-3">
        <label htmlFor={id} className="leading-8 font-futuristagothic text-future-black text-2xl">
          {label}
        </label>
        {notice && <span className="text-xs text-future-deep-gray mt-1 font-medium">{notice}</span>}
      </div>
      <div className="grid grid-cols-1">
        <Input
          className="px-1 py-1 border-[#303030] border-0 border-b-[1.2px] rounded-none focus:border-0
          focus:border-b-[1.5px] focus:ring-0"
          name="weight"
          placeholder="In lbs"
          value={inputValue}
          onChange={handleInputsChange}
          error={Boolean(error)}
          maxLength={3}
        />
      </div>

      {error && <span className="text-future-red text-left text-xs mt-2 inline-flex">{error}</span>}
    </div>
  );
}

CustomWeightInputBuilder.defaultProps = {
  notice: undefined,
  error: undefined,
};

export default CustomWeightInputBuilder;

import React from 'react';
import {
  InputBuilder,
  DateInputBuilder,
  Button,
  AddressInputBuilder,
  CheckBoxInputBuilder,
  RadioInputBuilder,
  TextBoxInputBuilder,
  CustomHeightInputBuilder,
  CustomWeightInputBuilder,
  CustomBloodPressureInputBuilder,
  CustomIdentificationInputBuilder,
  CustomCardSelectorInputBuilder,
  CustomChipInputBuilder,
  CustomFilesInputBuilder,
  MarkdownHTML,
} from '..';
import { Answer, Question } from '../../context/visitContext';
import AtomicHeart from '../../assets/images/image_FUTURE_atomic_heart.png';

type CareQuestionsProps = {
  onNextQuestions: () => void;
  onQuestionAnswered: (answer: Answer) => void;
  questionContentRef?: React.RefObject<HTMLDivElement>;
  isLastQuestion?: boolean;
  unAnsweredQuestions: string[];
  questions: Question[];
  answers: Answer[];
};

function CareQuestions({
  onQuestionAnswered,
  onNextQuestions,
  unAnsweredQuestions,
  questionContentRef,
  isLastQuestion,
  questions,
  answers,
}: CareQuestionsProps) {
  const handleInputValue = (questionId: string) => {
    let ansValue = '';
    if (answers && answers.length > 0) {
      ansValue = answers.find(answer => answer.questionKey === questionId)?.answers[0] || '';
    }
    return ansValue;
  };

  const handleInputMultiValues = (questionId: string) => {
    let ansValue: string[] = [];
    if (answers && answers.length > 0) {
      ansValue = answers.find(answer => answer.questionKey === questionId)?.answers || [];
    }
    return ansValue;
  };

  return (
    <>
      <div
        className="space-y-10 tablet:space-y-12 pt-[30px] tablet:pt-[40px]"
        ref={questionContentRef}
      >
        {questions.map(question => {
          switch (question.type) {
            case 'OPEN-TEXT':
              return (
                <InputBuilder
                  key={question.id}
                  value={handleInputValue(question.id)}
                  onInputChange={onQuestionAnswered}
                  notice={question?.hint || question?.notice}
                  error={unAnsweredQuestions.includes(question.id) ? question?.error : undefined}
                  id={question.id}
                  label={question?.text || ''}
                  name={question.id}
                />
              );
            case 'OPEN-DATE':
              return (
                <DateInputBuilder
                  key={question.id}
                  value={handleInputValue(question.id)}
                  onInputChange={onQuestionAnswered}
                  notice={question?.hint || question?.notice}
                  error={unAnsweredQuestions.includes(question.id) ? question?.error : undefined}
                  id={question.id}
                  label={question?.text || ''}
                />
              );
            case 'OPEN-ADDRESS':
              return (
                <AddressInputBuilder
                  key={question.id}
                  value={handleInputMultiValues(question.id)}
                  onInputChange={onQuestionAnswered}
                  notice={question?.hint || question?.notice}
                  error={unAnsweredQuestions.includes(question.id) ? question?.error : undefined}
                  id={question.id}
                  label={question?.text || ''}
                />
              );
            case 'MULTIPLE-CHOICE':
              return (
                <CheckBoxInputBuilder
                  key={question.id}
                  value={handleInputMultiValues(question.id)}
                  onInputChange={onQuestionAnswered}
                  notice={question?.hint || question?.notice}
                  error={unAnsweredQuestions.includes(question.id) ? question?.error : undefined}
                  id={question.id}
                  label={question?.text || ''}
                  inputs={
                    question?.options ||
                    question?.children?.map(child => ({ ...child, text: child.option })) ||
                    []
                  }
                />
              );
            case 'BINARY':
            case 'BINARY-SELECT':
              return (
                <RadioInputBuilder
                  key={question.id}
                  value={handleInputValue(question.id)}
                  onInputChange={onQuestionAnswered}
                  notice={question?.hint || question?.notice}
                  error={unAnsweredQuestions.includes(question.id) ? question?.error : undefined}
                  id={question.id}
                  label={question?.text || ''}
                  inputs={
                    question?.options ||
                    question?.children?.map(child => ({ ...child, text: child.option })) ||
                    []
                  }
                />
              );
            case 'OPEN-TEXTAREA':
              return (
                <TextBoxInputBuilder
                  key={question.id}
                  value={handleInputValue(question.id)}
                  onInputChange={onQuestionAnswered}
                  notice={question?.hint || question?.notice}
                  error={unAnsweredQuestions.includes(question.id) ? question?.error : undefined}
                  id={question.id}
                  label={question?.text || ''}
                  name={question.id}
                />
              );
            case 'OPEN-HEIGHT':
              return (
                <CustomHeightInputBuilder
                  key={question.id}
                  value={handleInputValue(question.id)}
                  onInputChange={onQuestionAnswered}
                  notice={question?.hint || question?.notice}
                  error={unAnsweredQuestions.includes(question.id) ? question?.error : undefined}
                  id={question.id}
                  label={question?.text || ''}
                />
              );
            case 'OPEN-WEIGHT':
              return (
                <CustomWeightInputBuilder
                  key={question.id}
                  value={handleInputValue(question.id)}
                  onInputChange={onQuestionAnswered}
                  notice={question?.hint || question?.notice}
                  error={unAnsweredQuestions.includes(question.id) ? question?.error : undefined}
                  id={question.id}
                  label={question?.text || ''}
                />
              );
            case 'OPEN-BLOOD-PRESSURE':
              return (
                <CustomBloodPressureInputBuilder
                  key={question.id}
                  value={handleInputValue(question.id)}
                  onInputChange={onQuestionAnswered}
                  notice={question?.hint || question?.notice}
                  error={unAnsweredQuestions.includes(question.id) ? question?.error : undefined}
                  id={question.id}
                  label={question?.text || ''}
                />
              );
            case 'OPEN-IMAGE':
              return (
                <CustomIdentificationInputBuilder
                  key={question.id}
                  value={handleInputMultiValues(question.id)}
                  onInputChange={onQuestionAnswered}
                  error={unAnsweredQuestions.includes(question.id) ? question?.error : undefined}
                  notice={question?.text || ''}
                  id={question.id}
                  label="Identification"
                />
              );
            case 'OPEN-FILES':
            case 'OPEN-FILE':
              return (
                <CustomFilesInputBuilder
                  key={question.id}
                  value={handleInputMultiValues(question.id)}
                  onInputChange={onQuestionAnswered}
                  type={question.type}
                  error={unAnsweredQuestions.includes(question.id) ? question?.error : undefined}
                  notice={question?.hint || question?.notice}
                  label={question?.text || ''}
                  id={question.id}
                />
              );
            case 'CUSTOM-BINARY-SELECT':
              return (
                <CustomCardSelectorInputBuilder
                  key={question.id}
                  value={handleInputValue(question.id)}
                  onInputChange={onQuestionAnswered}
                  id={question.id}
                  label={question?.text || ''}
                  inputs={
                    question?.options ||
                    question?.children?.map(child => ({ ...child, text: child.option })) ||
                    []
                  }
                />
              );
            case 'CUSTOM-BINARY':
              return (
                <CustomChipInputBuilder
                  key={question.id}
                  value={handleInputValue(question.id)}
                  onInputChange={onQuestionAnswered}
                  notice={question?.hint || question?.notice}
                  error={unAnsweredQuestions.includes(question.id) ? question?.error : undefined}
                  id={question.id}
                  label={question?.text || ''}
                  inputs={
                    question?.options ||
                    question?.children?.map(child => ({ ...child, text: child.option })) ||
                    []
                  }
                />
              );
            case 'BREAK-POINT':
              return (
                <div key={question.id}>
                  <div className="relative rounded-xl h-[200px] tablet:h-[250px] mb-5">
                    <img
                      src={AtomicHeart}
                      className="rounded-xl absolute object-center object-cover h-[180px]
                      bottom-0 left-0 right-0 tablet:h-[230px] mx-auto"
                      alt="visit break graphic"
                    />
                  </div>

                  <div className="content-text place-content-center">
                    <MarkdownHTML
                      className="text-sm text-center text-future-black px-1"
                      content={question.text}
                    />
                  </div>
                </div>
              );
            default:
              return null;
          }
        })}
      </div>
      {!questions.find(question => question.type === 'CUSTOM-BINARY-SELECT') && (
        <div className="text-center w-full flex flex-col items-center">
          <Button
            label={isLastQuestion ? 'Submit' : 'Continue'}
            onClick={onNextQuestions}
            className="!px-2 !w-[210px]"
          />
        </div>
      )}
    </>
  );
}

CareQuestions.defaultProps = {
  questionContentRef: undefined,
  isLastQuestion: undefined,
};

export default CareQuestions;

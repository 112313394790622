import React from 'react';
import { Icon, Modal } from '../..';
import useFetchAsync from '../../../hooks/useFetchAsync';
import readAWSS3File from '../../../utils/readAWSS3File';
import resizeImage from '../../../utils/resizeImage';

type PhotoPreviewerProps = {
  title: string;
  photo: string;
  changingPhoto: boolean;
  tempCroppedPhoto?: File;
  onCloseModal: () => void;
  onChangePhoto?: (file: File) => void;
};

function PhotoPreviewer({
  photo,
  title,
  changingPhoto,
  onCloseModal,
  onChangePhoto,
  tempCroppedPhoto,
}: PhotoPreviewerProps) {
  const [dragging, setDragging] = React.useState(false);
  const [uploadedPhoto, setUploadedPhoto] = React.useState<File>();
  const [loadingPhoto, setLoadingPhoto] = React.useState(true);
  const { data, status, error, run } = useFetchAsync();

  const previewPhoto = React.useMemo(() => {
    if (tempCroppedPhoto) {
      return URL.createObjectURL(tempCroppedPhoto);
    }

    if (uploadedPhoto) {
      return URL.createObjectURL(uploadedPhoto);
    }

    return (data || photo) as string;
  }, [data, photo, uploadedPhoto, tempCroppedPhoto]);

  React.useEffect(() => {
    const readingPhoto = readAWSS3File('document', photo);
    run(readingPhoto);
  }, [photo, run]);

  const handlePhotoUpload = async (file: File) => {
    const resizedFile = await resizeImage(file, 0.5);
    setUploadedPhoto(resizedFile);
    onChangePhoto?.(resizedFile);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);
    handlePhotoUpload(event.dataTransfer.files[0]);
  };

  return (
    <Modal onClose={onCloseModal} show>
      <div className="rounded-xl bg-white tablet:w-[600px]">
        <div className="flex flex-col p-8 pt-8 pb-10">
          <h3 className="text-future-black text-[20px] font-semibold">{title}</h3>

          <div
            className={`relative flex flex-col items-center justify-center 
            mt-[30px] rounded-xl bg-[#F3F3F3] border-2 border-future-deep-gray border-dashed 
            h-[250px] w-full hover:bg-[#EDF9FF]/60 
            ${dragging ? 'bg-[#EDF9FF]/60' : ''}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <label htmlFor="droppedPhotoZone" className="cursor-pointer">
              {!previewPhoto ? (
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg
                    aria-hidden="true"
                    className="w-10 h-9 mb-3 text-future-black"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    />
                  </svg>
                  <p className="mb-2 text-sm text-future-black">
                    <span className="font-medium">Click to upload</span> or drag and drop
                  </p>
                  <p className="text-xs text-future-black">PNG or JPG</p>
                </div>
              ) : (
                <div className="relative w-fit mx-auto">
                  {((previewPhoto && changingPhoto) ||
                    loadingPhoto ||
                    status === 'IDLE' ||
                    status === 'LOADING') && (
                    <Icon
                      id="loader"
                      className="w-10 inline-flex absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
                    />
                  )}
                  <div className="overflow-hidden h-[200px]">
                    <img
                      id="photoPreview"
                      role="presentation"
                      onLoad={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
                        const image = (event.target as HTMLImageElement).getAttribute('src');
                        if (image !== null) {
                          URL.revokeObjectURL(image);
                        }
                        setLoadingPhoto(false);
                      }}
                      className="w-full h-full object-cover"
                      alt="Preview"
                      src={previewPhoto}
                    />
                  </div>
                </div>
              )}

              <input
                id="droppedPhotoZone"
                type="file"
                className="hidden"
                onChange={event => {
                  const { files } = event.target;
                  if (files && files.length > 0) {
                    handlePhotoUpload(files[0]);
                  }
                  // eslint-disable-next-line no-param-reassign
                  event.target.value = '';
                }}
              />
            </label>
          </div>

          <span className="text-[15px] text-future-black mt-2 font-medium">
            <span className="text-future-black">*</span> Image files only. Supported formats include
            JPG and PNG
          </span>

          <div className="relative w-[230px] mt-[50px] mx-auto">
            <button
              onClick={onCloseModal}
              disabled={changingPhoto}
              type="button"
              className={`text-sm rounded-full bg-primary-gradient text-future-black inline-flex 
              w-full items-center hover:opacity-[0.85] h-14 justify-center disabled:text-gray-300 
              disabled:opacity-[0.85] ${
                changingPhoto ? 'disabled:cursor-wait' : 'disabled:cursor-not-allowed'
              }`}
            >
              {!changingPhoto ? 'Done' : 'Please wait...'}
              {changingPhoto && <Icon id="loader" className="w-5 h-5 ml-4" />}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

PhotoPreviewer.defaultProps = {
  onChangePhoto: undefined,
  tempCroppedPhoto: undefined,
};

export default PhotoPreviewer;

import React from 'react';
import { isEmpty } from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import Icon from '../../Icon/Icon';
import uploadAWSS3File from '../../../utils/uploadAWSS3File';
import resizeImage from '../../../utils/resizeImage';

type Answer = {
  questionKey: string;
  answers: Array<string>;
};

type InputBuilderProps = {
  id: string;
  label: string;
  value: string[];
  error?: string;
  notice?: string;
  onInputChange: (answers: Answer) => void;
};

function CustomIdentificationInputBuilder({
  id,
  label,
  value,
  error,
  notice,
  onInputChange,
}: InputBuilderProps) {
  const [file, setFile] = React.useState<Record<string, string>>(() => {
    if (value && value.length > 0) {
      return {
        idFile: value[0],
      };
    }

    return {
      idFile: '',
    };
  });

  const [uploadingFile, setUploadingFile] = React.useState<Record<string, boolean>>({
    idFile: false,
  });

  const handleFileProcessing = async (inputFile: File, name: string) => {
    const filename = inputFile?.name;

    const fileType = inputFile?.type || 'image/jpeg';

    const fileKey = `document/${name}_${uuidv4()}.${filename ? filename.split('.').pop() : 'jpeg'}`;

    const uploadedDocx = await uploadAWSS3File(inputFile, fileKey, fileType, 'public');

    setUploadingFile(uploads => ({ ...uploads, [name]: false }));

    setFile(upload => {
      return { ...upload, [name]: uploadedDocx };
    });

    const uploadedFiles = { ...file, [name]: uploadedDocx };

    const answers = Object.values(uploadedFiles);

    if (answers && answers.every(item => !isEmpty(item))) {
      onInputChange({ questionKey: id, answers });
    } else {
      onInputChange({ questionKey: id, answers: [''] });
    }
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files, name } = event.target;

    if (files && files.length > 0) {
      setUploadingFile(uploads => ({ ...uploads, [name]: true }));

      let getFile = files[0];

      if (files[0].type.startsWith('image/')) {
        getFile = await resizeImage(files[0], 0.5);
      }

      handleFileProcessing(getFile, name);
    }
  };

  return (
    <div>
      <div className="flex flex-col mb-6">
        <label htmlFor={id} className="leading-8 font-futuristagothic text-future-black text-2xl">
          {label}
        </label>
        {notice && <span className="text-xs text-future-deep-gray mt-1 font-medium">{notice}</span>}
      </div>
      <div className="fs-exclude grid grid-cols-1 tablet:grid-cols-2 gap-8 tablet:gap-16">
        <div>
          <div className="bg-[#F3F3F3] rounded-xl">
            <div
              className="bg-white rounded-xl flex flex-col 
            items-center p-5 text-center h-[200px] shadow-[0_0_12px_rgba(0,0,0,0.1)]"
            >
              <h3 className="">Identification</h3>
              <p className="text-[13px] mt-2">Driver&apos;s License, Passport, School I.D.</p>
              {uploadingFile.idFile ? (
                <Icon id="loader" className="w-10 mt-3" />
              ) : (
                <>
                  {!isEmpty(file.idFile) ? (
                    <span className="mt-3 w-12 h-12 bg-future-black flex justify-center rounded-full items-center">
                      <Icon id="checkmark" className="w-5 h-5 text-river-blue" />
                    </span>
                  ) : (
                    <Icon id="iconIDVerificationUpload" className="w-10 mt-3" />
                  )}
                  <span />
                </>
              )}
            </div>
            <span className="relative cursor-pointer text-xs block p-2 text-center text-future-deep-gray">
              UPLOAD ID
              <input
                className="absolute w-auto h-10 file:cursor-pointer right-0 left-0
                top-0 cursor-pointer bg-slate-500 opacity-0"
                type="file"
                name="idFile"
                accept="image/*"
                onChange={event => handleFileUpload(event)}
              />
            </span>
          </div>
        </div>
      </div>
      {error && <span className="text-future-red text-left text-xs mt-2 inline-flex">{error}</span>}
    </div>
  );
}

CustomIdentificationInputBuilder.defaultProps = {
  notice: undefined,
  error: undefined,
};

export default CustomIdentificationInputBuilder;

import * as CryptoJS from 'crypto-js';
import env from './env';

const passPhrase = env('FUTURE_PASSPHRASE') || '';

/**
 * Cookie keys are; _river_future_tokid(token id) | _river_future_usrid(user id) |
 * _river_future_unread_msg (unread msgs) | _river_future_error_ch_msgs (error chat messages)
 * @param key
 * @param value
 */
const setToLocalStorage = (key: string, value: string) => {
  const cipheredValue = CryptoJS.AES.encrypt(value, passPhrase).toString();
  localStorage.setItem(key, cipheredValue);
};

export const setToSessionStorage = (key: string, value: string) => {
  const cipheredValue = CryptoJS.AES.encrypt(value, passPhrase).toString();
  sessionStorage.setItem(key, cipheredValue);
};

export default setToLocalStorage;

import React from 'react';

type Answer = {
  questionKey: string;
  answers: Array<string>;
};

type CustomInputBuilderProps = {
  id: string;
  label: string;
  value: string;
  inputs: Array<{
    id: string;
    priority: number;
    text: string;
  }>;
  onInputChange: (answers: Answer) => void;
};

function CustomCardSelectorInputBuilder({
  id,
  label,
  value,
  inputs,
  onInputChange,
}: CustomInputBuilderProps) {
  const handleCardSelected = (textValue: string) => {
    onInputChange({
      questionKey: id,
      answers: [textValue],
    });
  };

  return (
    <div>
      <div className="flex flex-col mb-6">
        <label htmlFor={id} className="leading-8 font-futuristagothic text-future-black text-2xl">
          {label}
        </label>
      </div>

      <div
        className={`grid grid-cols-1 gap-5 ${
          inputs.length > 2 ? 'tablet:grid-cols-3' : 'tablet:grid-cols-2'
        }`}
      >
        {inputs &&
          inputs.map(input => (
            <div
              aria-hidden
              key={input.id}
              onClick={() => handleCardSelected(input.id)}
              className="bg-primary-gradient bg-future-violet h-[130px] tablet:h-[140px] tablet:min-h-fit 
              laptop:min-h-[140px] relative rounded-xl cursor-pointer"
            >
              <h3 className="font-medium text-sm absolute bottom-2 left-4 z-10">{input.text}</h3>
            </div>
          ))}
      </div>
    </div>
  );
}

export default CustomCardSelectorInputBuilder;

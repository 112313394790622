/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { ReactComponent as ImageVideoCallVisitGraphicIllustration } from '../../assets/images/image_video_call_visit_graphic_illustration.svg';
import { ReactComponent as ImageChatCardTexture } from '../../assets/images/image_chat_card_texture.svg';
import { ReactComponent as ImageBrokerBanner } from '../../assets/images/image_broker_banner.svg';
import { ReactComponent as ImageDemoMaskBg01 } from '../../assets/images/image_demo_mask_bg_01.svg';
import { ReactComponent as ImageDemoMaskBg02 } from '../../assets/images/image_demo_mask_bg_02.svg';
import { ReactComponent as ImageDemoMaskBg03 } from '../../assets/images/image_demo_mask_bg_03.svg';

type GraphicProps = {
  id: string;
  className?: string;
  onClick?: () => void;
};

const allGraphics: Record<string, (props: React.SVGProps<SVGSVGElement>) => JSX.Element | any> = {
  graphicVideoCallVisitIllustration: ImageVideoCallVisitGraphicIllustration,
  graphicChatCardTexture: ImageChatCardTexture,
  imageBrokerBanner: ImageBrokerBanner,
  graphicDemoMaskBg01: ImageDemoMaskBg01,
  graphicDemoMaskBg02: ImageDemoMaskBg02,
  graphicDemoMaskBg03: ImageDemoMaskBg03,
};

function Graphic({ id, ...rest }: GraphicProps) {
  const SelectedGraphic = allGraphics[id];
  return SelectedGraphic ? <SelectedGraphic id={id} {...rest} /> : null;
}

Graphic.defaultProps = {
  className: '',
  onClick: () => false,
};

export default Graphic;

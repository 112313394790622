import React from 'react';
import { useLocation } from 'react-router-dom';
import { equals } from 'ramda';
import moment from 'moment';
import withAppListener from '../WithAppListener';
import { Alert, Navigation } from '../../components';
import { AuthUser, showAlert, signOut, useAuth } from '../../context/authContext';
import readAWSS3File from '../../utils/readAWSS3File';
import getColorFromImage from '../../utils/getColorFromImage';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import { listenToLatestVisit } from '../../context/visitContext';
import { VISIT_STATUS } from '../../utils/constants';
import { paths } from '../../routes';
import { IncomingCallAlert } from '../../components/Alert/Alert';

function Dashboard({ children }: { children: React.ReactNode }) {
  const [{ user, modalState, alert }, dispatch] = useAuth();
  const [incomingVideoCall, setIncomingVideoCall] = React.useState<{
    id: string;
    acceptedAt: string;
    createdAt: string;
    link: string;
  }>();
  const location = useLocation();

  const onVideoPage = (location?.pathname || '').includes('/virtual-visit/video-call');

  React.useEffect(() => {
    const clinicStartTime = moment('7:00 am', 'h:mm a');
    const clinicEndTime = moment('5:00 pm', 'h:mm a');

    // using The IANA time zone identifier for Minnesota and Texas which is America/Chicago
    const currentTime = moment(moment().tz('America/Chicago').format('h:mm a'), 'h:mm a');
    const isWeekend = currentTime.day() === 6 || currentTime.day() === 0;
    const opened = currentTime.isBetween(clinicStartTime, clinicEndTime) && !isWeekend;

    if (!opened) {
      dispatch({ type: 'SET_VIRTUAL_CLINIC_STATUS', payload: 'CLOSED' });
      return;
    }

    if (
      user?.latestVisit &&
      [VISIT_STATUS.UNANSWERED, VISIT_STATUS.PENDING].includes(user?.latestVisit?.status)
    ) {
      dispatch({ type: 'SET_VIRTUAL_CLINIC_STATUS', payload: 'UNAVAILABLE' });
    } else {
      dispatch({ type: 'SET_VIRTUAL_CLINIC_STATUS', payload: 'OPENED' });
    }
  }, [dispatch, user]);

  React.useEffect(() => {
    if (!user || user.signedAvatar) return; // Return if user does not exist or has signed photo

    (async function syncPhotoMisc() {
      const userPhoto = await readAWSS3File('avatar', user?.profilePicture);

      const mergedAuthUser = {
        ...user,
        signedAvatar: userPhoto,
        avatarPlaceholderColor: userPhoto ? await getColorFromImage(userPhoto) : '',
      } as AuthUser;

      if (!equals(user, mergedAuthUser)) {
        dispatch({ type: 'SET_AUTH_USER', payload: mergedAuthUser });
      }
    })();
  }, [user, dispatch]);

  React.useEffect(() => {
    let interval = 0 as any;

    (async () => {
      const latestVisit = await listenToLatestVisit();
      if (user && latestVisit && !equals(user.latestVisit, latestVisit)) {
        dispatch({
          type: 'SET_AUTH_USER',
          payload: { ...user, latestVisit },
        });
      }
    })();

    interval = setInterval(async () => {
      const latestVisit = await listenToLatestVisit();

      if (latestVisit?.calls && latestVisit?.calls?.length > 0 && !onVideoPage) {
        const incomingCall = latestVisit.calls[latestVisit.calls.length - 1];

        // Video call link will be valid for 30 minutes (1800 seconds)
        const secondsAgo = moment().subtract(1800, 'seconds');
        const createdAt = moment(incomingCall.createdAt);

        if (createdAt.isAfter(secondsAgo) && !incomingCall.acceptedAt) {
          setIncomingVideoCall(incomingCall);
        }
      }

      if (user && latestVisit && !equals(user.latestVisit, latestVisit)) {
        dispatch({
          type: 'SET_AUTH_USER',
          payload: { ...user, latestVisit },
        });
      }
    }, 1000 * 10);

    return () => {
      clearInterval(interval);
    };
  }, [user, dispatch, onVideoPage]);

  const handleAcceptVideoCall = async () => {
    if (!incomingVideoCall || !user?.latestVisit) return;

    const whereByLink = incomingVideoCall.link;

    const visitId = user.latestVisit.id;

    const callId = incomingVideoCall.id;

    window.location.href = `${window.location.origin}${paths.VIDEO_CALL_INTRO_URL_PATH}?link=${whereByLink}&visitId=${visitId}&callId=${callId}`;
  };

  // Load messages conversation with assigned guide
  // Enable Page not to scroll when modal opens
  return (
    <div className="h-auto bg-river-background flex flex-col">
      <Navigation user={user} onSignOut={() => signOut()} />
      <main
        className={`relative flex-auto pt-20 tablet:pt-24 px-7 tablet:px-10 desktop:px-16 h-full 
        ${modalState === 'OPENED' ? 'overflow-y-hidden' : ''}`}
      >
        <ErrorBoundary>
          <>
            <Alert
              onClose={() => showAlert(dispatch)}
              type={alert.type}
              show={alert.show}
              className="z-40 -mx-7 tablet:-mx-10 desktop:-mx-16 !top-auto"
              message={alert.message}
            />

            {incomingVideoCall && user?.latestVisit?.provider && (
              <IncomingCallAlert
                show
                caller={{
                  name: user?.latestVisit?.provider.firstName
                    ? `${user?.latestVisit?.provider.firstName} ${user?.latestVisit?.provider.lastName}`
                    : 'River Provider',
                  photo: user?.latestVisit?.provider?.profilePicture || '',
                }}
                onAcceptCall={() => handleAcceptVideoCall()}
              />
            )}

            {children}
          </>
        </ErrorBoundary>
      </main>
    </div>
  );
}

export default withAppListener(Dashboard);

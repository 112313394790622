import * as React from 'react';
import moment from 'moment';
import { isEmpty } from 'ramda';
import { Icon, Image, Modal } from '../..';
import { Note } from '../../../context/visitContext';

type NoteProps = {
  note: Note;
  onCloseModal: () => void;
};

function NoteViewer({ note, onCloseModal }: NoteProps) {
  return (
    <Modal onClose={onCloseModal} show>
      <div className="rounded-xl bg-white tablet:w-[650px]">
        <div className="flex flex-col p-8 pt-6 pb-8">
          <h3
            className="flex flex-row items-center text-future-black text-[22px] 
            font-futuristagothic border-b-[1px] border-[#e9ecef] pb-3"
          >
            <Icon
              id="iconCareNotesAttachment"
              className="w-[30px] mr-3 flex-shrink-0 text-future-deep-gray"
            />
            NOTE
          </h3>

          <div className="mt-4 mb-7">{note.text}</div>

          <div className="flex flex-row justify-between items-center">
            <div className=" flex items-center">
              <Image
                className="h-[50px] w-[50px] rounded-full mr-2"
                src={note.author?.signedAvatar || 'image_FUTURE_crystal_ball.png'}
                alt="author avatar"
                bgColor="#fbd48c"
              />
              <p>{`${note.author?.firstName} ${note.author?.lastName}`}</p>
            </div>

            <p className="text-[#969494]">{moment(note.date).format('MM/DD/YYYY')}</p>
          </div>

          {!isEmpty(note.attachment) && (
            <div className="mt-7">
              <a
                href={note.attachment}
                target="_blank"
                rel="noreferrer"
                className="bg-[#F2F4F5] text-[13px] rounded-full px-3 inline-flex items-center h-[35px] float-right"
              >
                View attachment
                <Icon id="iconMessageTimestampAttached" className="w-4 text-future-black ml-1" />
              </a>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default NoteViewer;
